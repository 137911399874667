import type { DocumentType } from '@kanbu/schema';
import { DocumentStatus } from '@kanbu/schema/enums';
import type { PopulateParams } from '@kanbu/shared';
import {
  createFileRoute,
  type SearchSchemaInput,
} from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import {
  DocumentsStatusSummary,
  type StatisticItem,
} from '@/features/chats/components/documentsStatusSummary/DocumentsStatusSummary';
import {
  AddDocumentsModal,
  DocumentContentModal,
  useDocumentCols,
} from '@/features/documents';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

const populate: PopulateParams = [
  'documentsCount',
  'parsingErrorDocumentsCount',
  'embeddingErrorDocumentsCount',
  'uploadingDocumentsCount',
  'readyDocumentsCount',
  'parsingDocumentsCount',
  'parsingAsyncDocumentsCount',
  'parsedDocumentsCount',
  'embeddingDocumentsCount',
  'doneDocumentsCount',
];

export const Route = createFileRoute('/_baseLayout/chats/$chatId/documents/')({
  component: DocumentsPage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId, populate }),
  validateSearch: (search: { modalOpen?: boolean } & SearchSchemaInput) => {
    // Easy solution. Condition added so that we do not pollute URL with unnecessary params
    if (search?.modalOpen) {
      // eslint-disable-next-line sonarjs/no-redundant-boolean
      return { modalOpen: search.modalOpen as boolean };
    }
  },
});

export function DocumentsPage() {
  const { chatId } = Route.useParams();
  // Not spreading the useSearch here, as it can be undefined.
  const search = Route.useSearch();
  const { t } = useTranslation(['globals', 'documents', 'chats']);
  const columns = useDocumentCols(chatId);
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId, populate });
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null,
  );

  const handleQuery = useTableQuery(trpcClient.documents.findAll.query, {
    populate: ['parsingMetadata', 'shortSummary'],
    where: {
      chat: chatId,
    },
  });

  const handleRow = useCallback(
    (_event: unknown, row: { original: DocumentType }) => {
      setSelectedDocument(row.original);
    },
    [],
  );

  const statistics = useMemo<StatisticItem[]>(() => {
    return [
      // Error states
      {
        status: DocumentStatus.ParsingError,
        count: Number(data.parsingErrorDocumentsCount || 0),
        isError: true,
      },
      {
        status: DocumentStatus.EmbeddingError,
        count: Number(data.embeddingErrorDocumentsCount || 0),
        isError: true,
      },

      // Processing states
      {
        status: DocumentStatus.Parsing,
        count: Number(data.parsingDocumentsCount || 0),
        isProcessing: true,
      },
      {
        status: DocumentStatus.ParsingAsync,
        count: Number(data.parsingAsyncDocumentsCount || 0),
        isProcessing: true,
      },
      {
        status: DocumentStatus.Parsed,
        count: Number(data.parsedDocumentsCount || 0),
        isProcessing: true,
      },
      {
        status: DocumentStatus.Embedding,
        count: Number(data.embeddingDocumentsCount || 0),
        isProcessing: true,
      },

      // Done state
      {
        status: DocumentStatus.Done,
        count: Number(data.doneDocumentsCount || 0),
        isDone: true,
      },
    ];
  }, [data]);

  return (
    <>
      <TablePage
        title={t('documents:texts.manage')}
        actions={
          <AddDocumentsModal chatId={chatId} defaultOpen={search?.modalOpen} />
        }
        breadcrumbs={[
          {
            label: data.name,
            to: '/chats/$chatId/edit',
            params: { chatId },
          },
          {
            label: t('globals:routeNames.documents'),
            to: '/chats/$chatId/documents',
            params: { chatId },
          },
        ]}
        columns={columns}
        queryKey={[...getQueryKey(trpc.documents.findAll), { chatId }]}
        onQueryFn={handleQuery}
        onRow={handleRow}
      >
        <DocumentsStatusSummary
          title={t('chats:texts.documentsStatistics')}
          statistics={statistics}
          total={Number(data.documentsCount || 0)}
        />
      </TablePage>

      <DocumentContentModal
        documentId={selectedDocument?.id}
        onClose={() => setSelectedDocument(null)}
      />
    </>
  );
}
