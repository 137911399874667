import {
  Agent,
  CapabilitiesPreset,
  Role,
  TonePreset,
  ResponseLengthPreset,
  ChatModel,
} from '@kanbu/schema/enums';
import { AgentDefaults, SUPPORTED_LANGUAGES } from '@kanbu/shared';
import { useFormApi, type FieldState } from 'informed';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';
import { useBoundStore } from '@/store/store';

export function BasicConfigFields() {
  const { hasAccess } = useAccess();
  const { t } = useTranslation(['glossary', 'enums', 'chats']);
  const formApi = useFormApi();
  const { user } = useBoundStore(state => ({
    user: state.user,
  }));

  const defaultCompanyName = user?.organization.name ?? 'Kanbu';

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t('glossary:labels.name'),
        required: true,
        defaultValue: AgentDefaults.name,
      },
      {
        name: 'agentConfig.category',
        label: t('glossary:labels.focus'),
        type: 'select',
        options: optionsFromEnum(CapabilitiesPreset, key =>
          t(`enums:capabilitiesPreset.${key}`),
        ),
        defaultValue: CapabilitiesPreset.CustomerSupport,
        showOptional: false,
        uiProps: {
          onChange: ({ value }: FieldState) => {
            formApi.setValue(
              'agentConfig.capabilities',
              t(`enums:capabilities.${value}` as never),
            );
          },
        },
      },
      {
        name: 'agentConfig.capabilities',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.furtherSpecifications'),
        type: 'text',
        uiProps: {
          rows: 5,
        },
        uiControl: 'textarea',
        required: true,
        defaultValue: t(
          `enums:capabilities.${CapabilitiesPreset.CustomerSupport}`,
        ),
      },
      {
        name: 'agentConfig.tonePreset',
        label: t('glossary:labels.tonePreset'),
        type: 'select',
        options: optionsFromEnum(TonePreset, key =>
          t(`enums:tonePreset.${key}`),
        ),
        defaultValue: TonePreset.Casual,
        showOptional: false,
      },
      {
        name: 'agentConfig.responseLengthPreset',
        label: t('glossary:labels.responseLengthPreset'),
        type: 'select',
        options: optionsFromEnum(ResponseLengthPreset, key =>
          t(`enums:responseLengthPreset.${key}`),
        ),
        defaultValue: ResponseLengthPreset.Concise,
        showOptional: false,
      },
      {
        name: 'agentConfig.language',
        label: t('glossary:labels.language'),
        type: 'select',
        options: {
          automatic: t('enums:chatLanguage.automatic'),
          ...Object.fromEntries(
            Object.entries(SUPPORTED_LANGUAGES).map(([key, value]) => [
              key,
              // @ts-expect-error types diff
              t(`enums:chatLanguage.${key}`),
            ]),
          ),
        },
        defaultValue: 'automatic',
        showOptional: false,
      },
      {
        name: 'agentConfig.companyName',
        label: t('glossary:labels.companyName'),
        defaultValue: defaultCompanyName,
        required: true,
      },
      {
        name: 'agentConfig.companyDescription',
        label: t('glossary:labels.companyDescription'),
        type: 'text',
        uiProps: {
          rows: 5,
        },
        uiControl: 'textarea',
        required: true,
        defaultValue: t('chats:texts.companyDescriptionDefault', {
          companyName: defaultCompanyName,
        }),
      },
      {
        name: 'agentConfig.showSources',
        label: t('glossary:labels.showSources'),
        type: 'checkbox',
        uiControl: 'switch',
        defaultValue: true,
        required: true,
      },
      {
        name: 'agentConfig.showFollowUpQuestions',
        label: t('glossary:labels.showFollowUpQuestions'),
        type: 'checkbox',
        uiControl: 'switch',
        tooltip: t('chats:texts.showFollowUpQuestionsDescription'),
        defaultValue: true,
        required: true,
      },
      {
        name: 'agent',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.agent'),
        type: 'select',
        options: optionsFromEnum(Agent, key => t(`enums:agent.${key}`)),
      },
      {
        name: 'model',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.model'),
        type: 'select',
        options: optionsFromEnum(ChatModel, key => t(`enums:chatModel.${key}`)),
      },
      {
        name: 'embeddingsVersion',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.embeddingsVersion'),
        type: 'number',
      },
    ],
    [t, hasAccess],
  );

  return (
    <Card title={t('glossary:labels.basicDetails')}>
      <SchemaFields schema={detailsSchema} />
    </Card>
  );
}
