import { z } from 'zod';

import { baseEntitySchema } from './baseContract';

export const tierConfigSchema = z.object({
  usersLimit: z.number().min(1),
});

export const organizationSchema = baseEntitySchema.extend({
  id: z.string().uuid(),
  name: z.string().max(150),
  url: z.string().url().max(150).optional(),
  monthlyUsageLimit: z.number().min(0).max(999_999_999.99).optional(),
  tierConfig: tierConfigSchema,
});

// Base organization schema has to be defined here because of cyclic imports
export const baseOrganizationEntitySchema = baseEntitySchema.extend({
  organization: organizationSchema,
});

// Create DTO schema
export const createOrganizationSchema = organizationSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

// Update DTO schema
export const updateOrganizationSchema = organizationSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type OrganizationDTO = z.infer<typeof organizationSchema>;
export type CreateOrganizationDTO = z.infer<typeof createOrganizationSchema>;
export type UpdateOrganizationDTO = z.infer<typeof updateOrganizationSchema>;
export type OrganizationTierConfig = z.infer<typeof tierConfigSchema>;
