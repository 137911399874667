import type { OrganizationType } from '@kanbu/schema';
import {
  createOrganizationSchema,
  updateOrganizationSchema,
} from '@kanbu/schema/contracts';
import { Role } from '@kanbu/schema/enums';
import { formatUtils } from '@kanbu/shared';
import { cn } from '@utima/ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

import { useOrganizationMutation } from '../hooks/useOrganizationMutation';

export type OrganizationFormProps = {
  data?: OrganizationType;
};

export const OrganizationForm = memo(function OrganizationForm({
  data,
}: OrganizationFormProps) {
  const { t } = useTranslation(['glossary']);
  const user = useBoundStore(state => state.user);
  const setUser = useBoundStore(state => state.setUser);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useOrganizationMutation();

  const { refetch: refetchUser } = trpc.users.me.useQuery(undefined, {
    enabled: false,
  });

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t('glossary:labels.name'),
        required: true,
      },
      {
        name: 'monthlyUsageLimit',
        label: t('glossary:labels.monthlyUsageLimit'),
        type: 'number',
        defaultValue: 5,
        required: true,
        uiProps: {
          addonAfter: '$',
        },
      },
      {
        $relevant: {
          $when: () => user?.role === Role.SuperAdmin,
          $fields: [
            {
              name: 'tierConfig.usersLimit',
              label: t('glossary:labels.usersLimit'),
              type: 'number',
              uiProps: {
                min: 1,
              },
              required: true,
            },
          ],
        },
      },
    ],
    [t],
  );

  const current = data?.currentMonthlyUsage ?? 0;
  const limit = data?.monthlyUsageLimit ?? 0;

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createOrganizationSchema}
      updateSchema={updateOrganizationSchema}
      onCreate={create}
      onUpdate={async values => {
        const updatedValue = await update(values);

        // Refetch user on organization update
        if (updatedValue.id === user?.organization.id) {
          const updatedUser = await refetchUser();

          if (updatedUser.data) {
            setUser(updatedUser.data);
          }
        }

        return updatedValue;
      }}
      onRemove={remove}
      basePath='/organizations'
      idParamName='organizationId'
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col />
      <EntityCRUDForm.Col>
        <MetaFields data={data}>
          <DisplayValue label={t('glossary:labels.monthlyUsageLimit')}>
            <span
              className={cn(
                'inline-flex gap-1 items-baseline',
                current > limit ? 'text-red-600' : 'text-green-600',
              )}
            >
              <span className='font-semibold'>
                {formatUtils.currencyDollar(current)}
              </span>
              /
              <span className='text-xs font-semibold'>
                {formatUtils.currencyDollar(limit)}
              </span>
            </span>
          </DisplayValue>
        </MetaFields>
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
