import type { AgentConfig } from '@kanbu/schema';
import { ChatModel, Role } from '@kanbu/schema/enums';
import type { TypedFormState } from '@utima/ui-informed';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';

export type AgentConfigFieldsProps = {};

export const AgentConfigFields = memo(
  function AgentConfigFields({}: AgentConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums']);
    const { hasAccess } = useAccess();
    const { schema } = useFieldsSchema(
      [
        {
          name: 'agentConfig.systemPrompt',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.systemPrompt'),
          type: 'text',
          uiProps: {
            rows: 10,
          },
          uiControl: 'textarea',
        },
        {
          name: 'agentConfig.hasWebSearch',
          label: t('glossary:labels.webSearch'),
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: false,
          visible: hasAccess([Role.SuperAdmin]),
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              !!(formState as TypedFormState<{ agentConfig: AgentConfig }>)
                .values?.agentConfig?.hasWebSearch,
            $fields: [
              {
                name: 'agentConfig.webSearchUrls',
                label: t('glossary:labels.webSearchUrls'),
                type: 'text',
                uiProps: {
                  rows: 5,
                },
                uiControl: 'textarea',
                parser: value => {
                  if (typeof value === 'string') {
                    return value
                      .split('\n')
                      .map(line => line.trim())
                      .filter(line => line !== '');
                  }

                  return value;
                },
                mask: value => {
                  if (Array.isArray(value)) {
                    return value.map((item: string) => `${item} \n`).join('');
                  }

                  return value;
                },
              },
            ],
          },
        },
        {
          name: 'agentConfig.imageSupport',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.imageSupport'),
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: false,
        },
        {
          name: 'agentConfig.smallModel',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'smallModel',
          type: 'select',
          options: optionsFromEnum(ChatModel, key =>
            t(`enums:chatModel.${key}`),
          ),
          required: true,
          defaultValue: ChatModel.Gpt4oMini,
        },
        {
          name: 'agentConfig.hybridAlpha',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'hybridAlpha',
          type: 'number',
          defaultValue: 0.4,
        },
        {
          name: 'agentConfig.rewriteRetries',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'rewriteRetries',
          type: 'number',
          defaultValue: 1,
        },
        {
          name: 'agentConfig.historyLimit',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'historyLimit',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'agentConfig.contextHistoryLimit',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'contextHistoryLimit',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'agentConfig.retrievalCount',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'retrievalCount',
          type: 'number',
          defaultValue: 50,
        },
        {
          name: 'agentConfig.rerank',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'rerank',
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: true,
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              !!(formState as TypedFormState<{ agentConfig: AgentConfig }>)
                .values?.agentConfig?.rerank,
            $fields: [
              {
                name: 'agentConfig.rerankCount',
                visible: hasAccess([Role.SuperAdmin]),
                label: 'rerankCount',
                type: 'number',
                defaultValue: 20,
              },
              {
                name: 'agentConfig.rerankWeight',
                visible: hasAccess([Role.SuperAdmin]),
                label: 'rerankWeight',
                type: 'number',
                defaultValue: 0.7,
              },
            ],
          },
        },
        {
          name: 'agentConfig.minScore',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.minScore'),
          type: 'number',
          defaultValue: 0,
        },
      ],
      [t, hasAccess],
    );

    return (
      <Card title={t('glossary:labels.agentConfig')}>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
