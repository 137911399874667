/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as BaseLayoutImport } from './routes/_baseLayout'
import { Route as AuthLayoutImport } from './routes/_authLayout'
import { Route as BaseLayoutIndexImport } from './routes/_baseLayout/index'
import { Route as BaseLayoutUsersIndexImport } from './routes/_baseLayout/users/index'
import { Route as BaseLayoutThreadsIndexImport } from './routes/_baseLayout/threads/index'
import { Route as BaseLayoutOrganizationsIndexImport } from './routes/_baseLayout/organizations/index'
import { Route as BaseLayoutChatsIndexImport } from './routes/_baseLayout/chats/index'
import { Route as BaseLayoutChatUsersIndexImport } from './routes/_baseLayout/chat-users/index'
import { Route as AuthLayoutVerifyIndexImport } from './routes/_authLayout/verify/index'
import { Route as AuthLayoutResetPasswordIndexImport } from './routes/_authLayout/reset-password/index'
import { Route as AuthLayoutRegisterIndexImport } from './routes/_authLayout/register/index'
import { Route as AuthLayoutLoginIndexImport } from './routes/_authLayout/login/index'
import { Route as AuthLayoutForgottenPasswordIndexImport } from './routes/_authLayout/forgotten-password/index'
import { Route as BaseLayoutUsersCreateImport } from './routes/_baseLayout/users/create'
import { Route as BaseLayoutOrganizationsCreateImport } from './routes/_baseLayout/organizations/create'
import { Route as BaseLayoutChatsCreateImport } from './routes/_baseLayout/chats/create'
import { Route as BaseLayoutUsersProfileIndexImport } from './routes/_baseLayout/users/profile/index'
import { Route as AuthLayoutRegisterSentIndexImport } from './routes/_authLayout/register/sent/index'
import { Route as AuthLayoutForgottenPasswordSentIndexImport } from './routes/_authLayout/forgotten-password/sent/index'
import { Route as BaseLayoutUsersUserIdEditImport } from './routes/_baseLayout/users/$userId/edit'
import { Route as BaseLayoutOrganizationsOrganizationIdEditImport } from './routes/_baseLayout/organizations/$organizationId/edit'
import { Route as BaseLayoutChatsChatIdEditImport } from './routes/_baseLayout/chats/$chatId/edit'
import { Route as BaseLayoutChatsChatIdOperatorsIndexImport } from './routes/_baseLayout/chats/$chatId/operators/index'
import { Route as BaseLayoutChatsChatIdFaqsIndexImport } from './routes/_baseLayout/chats/$chatId/faqs/index'
import { Route as BaseLayoutChatsChatIdDocumentsIndexImport } from './routes/_baseLayout/chats/$chatId/documents/index'
import { Route as BaseLayoutChatUsersChatUserIdThreadsIndexImport } from './routes/_baseLayout/chat-users/$chatUserId/threads/index'
import { Route as BaseLayoutChatsChatIdOperatorsCreateImport } from './routes/_baseLayout/chats/$chatId/operators/create'
import { Route as BaseLayoutChatsChatIdFaqsCreateImport } from './routes/_baseLayout/chats/$chatId/faqs/create'
import { Route as BaseLayoutChatsChatIdOperatorsOperatorIdEditImport } from './routes/_baseLayout/chats/$chatId/operators/$operatorId/edit'
import { Route as BaseLayoutChatsChatIdFaqsFaqIdEditImport } from './routes/_baseLayout/chats/$chatId/faqs/$faqId/edit'

// Create/Update Routes

const BaseLayoutRoute = BaseLayoutImport.update({
  id: '/_baseLayout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_authLayout',
  getParentRoute: () => rootRoute,
} as any)

const BaseLayoutIndexRoute = BaseLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutUsersIndexRoute = BaseLayoutUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutThreadsIndexRoute = BaseLayoutThreadsIndexImport.update({
  id: '/threads/',
  path: '/threads/',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutOrganizationsIndexRoute =
  BaseLayoutOrganizationsIndexImport.update({
    id: '/organizations/',
    path: '/organizations/',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsIndexRoute = BaseLayoutChatsIndexImport.update({
  id: '/chats/',
  path: '/chats/',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutChatUsersIndexRoute = BaseLayoutChatUsersIndexImport.update({
  id: '/chat-users/',
  path: '/chat-users/',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const AuthLayoutVerifyIndexRoute = AuthLayoutVerifyIndexImport.update({
  id: '/verify/',
  path: '/verify/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutResetPasswordIndexRoute =
  AuthLayoutResetPasswordIndexImport.update({
    id: '/reset-password/',
    path: '/reset-password/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const AuthLayoutRegisterIndexRoute = AuthLayoutRegisterIndexImport.update({
  id: '/register/',
  path: '/register/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutLoginIndexRoute = AuthLayoutLoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutForgottenPasswordIndexRoute =
  AuthLayoutForgottenPasswordIndexImport.update({
    id: '/forgotten-password/',
    path: '/forgotten-password/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const BaseLayoutUsersCreateRoute = BaseLayoutUsersCreateImport.update({
  id: '/users/create',
  path: '/users/create',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutOrganizationsCreateRoute =
  BaseLayoutOrganizationsCreateImport.update({
    id: '/organizations/create',
    path: '/organizations/create',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsCreateRoute = BaseLayoutChatsCreateImport.update({
  id: '/chats/create',
  path: '/chats/create',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutUsersProfileIndexRoute =
  BaseLayoutUsersProfileIndexImport.update({
    id: '/users/profile/',
    path: '/users/profile/',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const AuthLayoutRegisterSentIndexRoute =
  AuthLayoutRegisterSentIndexImport.update({
    id: '/register/sent/',
    path: '/register/sent/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const AuthLayoutForgottenPasswordSentIndexRoute =
  AuthLayoutForgottenPasswordSentIndexImport.update({
    id: '/forgotten-password/sent/',
    path: '/forgotten-password/sent/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const BaseLayoutUsersUserIdEditRoute = BaseLayoutUsersUserIdEditImport.update({
  id: '/users/$userId/edit',
  path: '/users/$userId/edit',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutOrganizationsOrganizationIdEditRoute =
  BaseLayoutOrganizationsOrganizationIdEditImport.update({
    id: '/organizations/$organizationId/edit',
    path: '/organizations/$organizationId/edit',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdEditRoute = BaseLayoutChatsChatIdEditImport.update({
  id: '/chats/$chatId/edit',
  path: '/chats/$chatId/edit',
  getParentRoute: () => BaseLayoutRoute,
} as any)

const BaseLayoutChatsChatIdOperatorsIndexRoute =
  BaseLayoutChatsChatIdOperatorsIndexImport.update({
    id: '/chats/$chatId/operators/',
    path: '/chats/$chatId/operators/',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdFaqsIndexRoute =
  BaseLayoutChatsChatIdFaqsIndexImport.update({
    id: '/chats/$chatId/faqs/',
    path: '/chats/$chatId/faqs/',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdDocumentsIndexRoute =
  BaseLayoutChatsChatIdDocumentsIndexImport.update({
    id: '/chats/$chatId/documents/',
    path: '/chats/$chatId/documents/',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatUsersChatUserIdThreadsIndexRoute =
  BaseLayoutChatUsersChatUserIdThreadsIndexImport.update({
    id: '/chat-users/$chatUserId/threads/',
    path: '/chat-users/$chatUserId/threads/',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdOperatorsCreateRoute =
  BaseLayoutChatsChatIdOperatorsCreateImport.update({
    id: '/chats/$chatId/operators/create',
    path: '/chats/$chatId/operators/create',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdFaqsCreateRoute =
  BaseLayoutChatsChatIdFaqsCreateImport.update({
    id: '/chats/$chatId/faqs/create',
    path: '/chats/$chatId/faqs/create',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute =
  BaseLayoutChatsChatIdOperatorsOperatorIdEditImport.update({
    id: '/chats/$chatId/operators/$operatorId/edit',
    path: '/chats/$chatId/operators/$operatorId/edit',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

const BaseLayoutChatsChatIdFaqsFaqIdEditRoute =
  BaseLayoutChatsChatIdFaqsFaqIdEditImport.update({
    id: '/chats/$chatId/faqs/$faqId/edit',
    path: '/chats/$chatId/faqs/$faqId/edit',
    getParentRoute: () => BaseLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authLayout': {
      id: '/_authLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_baseLayout': {
      id: '/_baseLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof BaseLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_baseLayout/': {
      id: '/_baseLayout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof BaseLayoutIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/create': {
      id: '/_baseLayout/chats/create'
      path: '/chats/create'
      fullPath: '/chats/create'
      preLoaderRoute: typeof BaseLayoutChatsCreateImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/organizations/create': {
      id: '/_baseLayout/organizations/create'
      path: '/organizations/create'
      fullPath: '/organizations/create'
      preLoaderRoute: typeof BaseLayoutOrganizationsCreateImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/users/create': {
      id: '/_baseLayout/users/create'
      path: '/users/create'
      fullPath: '/users/create'
      preLoaderRoute: typeof BaseLayoutUsersCreateImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_authLayout/forgotten-password/': {
      id: '/_authLayout/forgotten-password/'
      path: '/forgotten-password'
      fullPath: '/forgotten-password'
      preLoaderRoute: typeof AuthLayoutForgottenPasswordIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authLayout/login/': {
      id: '/_authLayout/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLayoutLoginIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authLayout/register/': {
      id: '/_authLayout/register/'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof AuthLayoutRegisterIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authLayout/reset-password/': {
      id: '/_authLayout/reset-password/'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthLayoutResetPasswordIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authLayout/verify/': {
      id: '/_authLayout/verify/'
      path: '/verify'
      fullPath: '/verify'
      preLoaderRoute: typeof AuthLayoutVerifyIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_baseLayout/chat-users/': {
      id: '/_baseLayout/chat-users/'
      path: '/chat-users'
      fullPath: '/chat-users'
      preLoaderRoute: typeof BaseLayoutChatUsersIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/': {
      id: '/_baseLayout/chats/'
      path: '/chats'
      fullPath: '/chats'
      preLoaderRoute: typeof BaseLayoutChatsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/organizations/': {
      id: '/_baseLayout/organizations/'
      path: '/organizations'
      fullPath: '/organizations'
      preLoaderRoute: typeof BaseLayoutOrganizationsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/threads/': {
      id: '/_baseLayout/threads/'
      path: '/threads'
      fullPath: '/threads'
      preLoaderRoute: typeof BaseLayoutThreadsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/users/': {
      id: '/_baseLayout/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof BaseLayoutUsersIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/edit': {
      id: '/_baseLayout/chats/$chatId/edit'
      path: '/chats/$chatId/edit'
      fullPath: '/chats/$chatId/edit'
      preLoaderRoute: typeof BaseLayoutChatsChatIdEditImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/organizations/$organizationId/edit': {
      id: '/_baseLayout/organizations/$organizationId/edit'
      path: '/organizations/$organizationId/edit'
      fullPath: '/organizations/$organizationId/edit'
      preLoaderRoute: typeof BaseLayoutOrganizationsOrganizationIdEditImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/users/$userId/edit': {
      id: '/_baseLayout/users/$userId/edit'
      path: '/users/$userId/edit'
      fullPath: '/users/$userId/edit'
      preLoaderRoute: typeof BaseLayoutUsersUserIdEditImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_authLayout/forgotten-password/sent/': {
      id: '/_authLayout/forgotten-password/sent/'
      path: '/forgotten-password/sent'
      fullPath: '/forgotten-password/sent'
      preLoaderRoute: typeof AuthLayoutForgottenPasswordSentIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authLayout/register/sent/': {
      id: '/_authLayout/register/sent/'
      path: '/register/sent'
      fullPath: '/register/sent'
      preLoaderRoute: typeof AuthLayoutRegisterSentIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_baseLayout/users/profile/': {
      id: '/_baseLayout/users/profile/'
      path: '/users/profile'
      fullPath: '/users/profile'
      preLoaderRoute: typeof BaseLayoutUsersProfileIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/faqs/create': {
      id: '/_baseLayout/chats/$chatId/faqs/create'
      path: '/chats/$chatId/faqs/create'
      fullPath: '/chats/$chatId/faqs/create'
      preLoaderRoute: typeof BaseLayoutChatsChatIdFaqsCreateImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/operators/create': {
      id: '/_baseLayout/chats/$chatId/operators/create'
      path: '/chats/$chatId/operators/create'
      fullPath: '/chats/$chatId/operators/create'
      preLoaderRoute: typeof BaseLayoutChatsChatIdOperatorsCreateImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chat-users/$chatUserId/threads/': {
      id: '/_baseLayout/chat-users/$chatUserId/threads/'
      path: '/chat-users/$chatUserId/threads'
      fullPath: '/chat-users/$chatUserId/threads'
      preLoaderRoute: typeof BaseLayoutChatUsersChatUserIdThreadsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/documents/': {
      id: '/_baseLayout/chats/$chatId/documents/'
      path: '/chats/$chatId/documents'
      fullPath: '/chats/$chatId/documents'
      preLoaderRoute: typeof BaseLayoutChatsChatIdDocumentsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/faqs/': {
      id: '/_baseLayout/chats/$chatId/faqs/'
      path: '/chats/$chatId/faqs'
      fullPath: '/chats/$chatId/faqs'
      preLoaderRoute: typeof BaseLayoutChatsChatIdFaqsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/operators/': {
      id: '/_baseLayout/chats/$chatId/operators/'
      path: '/chats/$chatId/operators'
      fullPath: '/chats/$chatId/operators'
      preLoaderRoute: typeof BaseLayoutChatsChatIdOperatorsIndexImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/faqs/$faqId/edit': {
      id: '/_baseLayout/chats/$chatId/faqs/$faqId/edit'
      path: '/chats/$chatId/faqs/$faqId/edit'
      fullPath: '/chats/$chatId/faqs/$faqId/edit'
      preLoaderRoute: typeof BaseLayoutChatsChatIdFaqsFaqIdEditImport
      parentRoute: typeof BaseLayoutImport
    }
    '/_baseLayout/chats/$chatId/operators/$operatorId/edit': {
      id: '/_baseLayout/chats/$chatId/operators/$operatorId/edit'
      path: '/chats/$chatId/operators/$operatorId/edit'
      fullPath: '/chats/$chatId/operators/$operatorId/edit'
      preLoaderRoute: typeof BaseLayoutChatsChatIdOperatorsOperatorIdEditImport
      parentRoute: typeof BaseLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutRouteChildren {
  AuthLayoutForgottenPasswordIndexRoute: typeof AuthLayoutForgottenPasswordIndexRoute
  AuthLayoutLoginIndexRoute: typeof AuthLayoutLoginIndexRoute
  AuthLayoutRegisterIndexRoute: typeof AuthLayoutRegisterIndexRoute
  AuthLayoutResetPasswordIndexRoute: typeof AuthLayoutResetPasswordIndexRoute
  AuthLayoutVerifyIndexRoute: typeof AuthLayoutVerifyIndexRoute
  AuthLayoutForgottenPasswordSentIndexRoute: typeof AuthLayoutForgottenPasswordSentIndexRoute
  AuthLayoutRegisterSentIndexRoute: typeof AuthLayoutRegisterSentIndexRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutForgottenPasswordIndexRoute: AuthLayoutForgottenPasswordIndexRoute,
  AuthLayoutLoginIndexRoute: AuthLayoutLoginIndexRoute,
  AuthLayoutRegisterIndexRoute: AuthLayoutRegisterIndexRoute,
  AuthLayoutResetPasswordIndexRoute: AuthLayoutResetPasswordIndexRoute,
  AuthLayoutVerifyIndexRoute: AuthLayoutVerifyIndexRoute,
  AuthLayoutForgottenPasswordSentIndexRoute:
    AuthLayoutForgottenPasswordSentIndexRoute,
  AuthLayoutRegisterSentIndexRoute: AuthLayoutRegisterSentIndexRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

interface BaseLayoutRouteChildren {
  BaseLayoutIndexRoute: typeof BaseLayoutIndexRoute
  BaseLayoutChatsCreateRoute: typeof BaseLayoutChatsCreateRoute
  BaseLayoutOrganizationsCreateRoute: typeof BaseLayoutOrganizationsCreateRoute
  BaseLayoutUsersCreateRoute: typeof BaseLayoutUsersCreateRoute
  BaseLayoutChatUsersIndexRoute: typeof BaseLayoutChatUsersIndexRoute
  BaseLayoutChatsIndexRoute: typeof BaseLayoutChatsIndexRoute
  BaseLayoutOrganizationsIndexRoute: typeof BaseLayoutOrganizationsIndexRoute
  BaseLayoutThreadsIndexRoute: typeof BaseLayoutThreadsIndexRoute
  BaseLayoutUsersIndexRoute: typeof BaseLayoutUsersIndexRoute
  BaseLayoutChatsChatIdEditRoute: typeof BaseLayoutChatsChatIdEditRoute
  BaseLayoutOrganizationsOrganizationIdEditRoute: typeof BaseLayoutOrganizationsOrganizationIdEditRoute
  BaseLayoutUsersUserIdEditRoute: typeof BaseLayoutUsersUserIdEditRoute
  BaseLayoutUsersProfileIndexRoute: typeof BaseLayoutUsersProfileIndexRoute
  BaseLayoutChatsChatIdFaqsCreateRoute: typeof BaseLayoutChatsChatIdFaqsCreateRoute
  BaseLayoutChatsChatIdOperatorsCreateRoute: typeof BaseLayoutChatsChatIdOperatorsCreateRoute
  BaseLayoutChatUsersChatUserIdThreadsIndexRoute: typeof BaseLayoutChatUsersChatUserIdThreadsIndexRoute
  BaseLayoutChatsChatIdDocumentsIndexRoute: typeof BaseLayoutChatsChatIdDocumentsIndexRoute
  BaseLayoutChatsChatIdFaqsIndexRoute: typeof BaseLayoutChatsChatIdFaqsIndexRoute
  BaseLayoutChatsChatIdOperatorsIndexRoute: typeof BaseLayoutChatsChatIdOperatorsIndexRoute
  BaseLayoutChatsChatIdFaqsFaqIdEditRoute: typeof BaseLayoutChatsChatIdFaqsFaqIdEditRoute
  BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute: typeof BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute
}

const BaseLayoutRouteChildren: BaseLayoutRouteChildren = {
  BaseLayoutIndexRoute: BaseLayoutIndexRoute,
  BaseLayoutChatsCreateRoute: BaseLayoutChatsCreateRoute,
  BaseLayoutOrganizationsCreateRoute: BaseLayoutOrganizationsCreateRoute,
  BaseLayoutUsersCreateRoute: BaseLayoutUsersCreateRoute,
  BaseLayoutChatUsersIndexRoute: BaseLayoutChatUsersIndexRoute,
  BaseLayoutChatsIndexRoute: BaseLayoutChatsIndexRoute,
  BaseLayoutOrganizationsIndexRoute: BaseLayoutOrganizationsIndexRoute,
  BaseLayoutThreadsIndexRoute: BaseLayoutThreadsIndexRoute,
  BaseLayoutUsersIndexRoute: BaseLayoutUsersIndexRoute,
  BaseLayoutChatsChatIdEditRoute: BaseLayoutChatsChatIdEditRoute,
  BaseLayoutOrganizationsOrganizationIdEditRoute:
    BaseLayoutOrganizationsOrganizationIdEditRoute,
  BaseLayoutUsersUserIdEditRoute: BaseLayoutUsersUserIdEditRoute,
  BaseLayoutUsersProfileIndexRoute: BaseLayoutUsersProfileIndexRoute,
  BaseLayoutChatsChatIdFaqsCreateRoute: BaseLayoutChatsChatIdFaqsCreateRoute,
  BaseLayoutChatsChatIdOperatorsCreateRoute:
    BaseLayoutChatsChatIdOperatorsCreateRoute,
  BaseLayoutChatUsersChatUserIdThreadsIndexRoute:
    BaseLayoutChatUsersChatUserIdThreadsIndexRoute,
  BaseLayoutChatsChatIdDocumentsIndexRoute:
    BaseLayoutChatsChatIdDocumentsIndexRoute,
  BaseLayoutChatsChatIdFaqsIndexRoute: BaseLayoutChatsChatIdFaqsIndexRoute,
  BaseLayoutChatsChatIdOperatorsIndexRoute:
    BaseLayoutChatsChatIdOperatorsIndexRoute,
  BaseLayoutChatsChatIdFaqsFaqIdEditRoute:
    BaseLayoutChatsChatIdFaqsFaqIdEditRoute,
  BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute:
    BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute,
}

const BaseLayoutRouteWithChildren = BaseLayoutRoute._addFileChildren(
  BaseLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof BaseLayoutRouteWithChildren
  '/': typeof BaseLayoutIndexRoute
  '/chats/create': typeof BaseLayoutChatsCreateRoute
  '/organizations/create': typeof BaseLayoutOrganizationsCreateRoute
  '/users/create': typeof BaseLayoutUsersCreateRoute
  '/forgotten-password': typeof AuthLayoutForgottenPasswordIndexRoute
  '/login': typeof AuthLayoutLoginIndexRoute
  '/register': typeof AuthLayoutRegisterIndexRoute
  '/reset-password': typeof AuthLayoutResetPasswordIndexRoute
  '/verify': typeof AuthLayoutVerifyIndexRoute
  '/chat-users': typeof BaseLayoutChatUsersIndexRoute
  '/chats': typeof BaseLayoutChatsIndexRoute
  '/organizations': typeof BaseLayoutOrganizationsIndexRoute
  '/threads': typeof BaseLayoutThreadsIndexRoute
  '/users': typeof BaseLayoutUsersIndexRoute
  '/chats/$chatId/edit': typeof BaseLayoutChatsChatIdEditRoute
  '/organizations/$organizationId/edit': typeof BaseLayoutOrganizationsOrganizationIdEditRoute
  '/users/$userId/edit': typeof BaseLayoutUsersUserIdEditRoute
  '/forgotten-password/sent': typeof AuthLayoutForgottenPasswordSentIndexRoute
  '/register/sent': typeof AuthLayoutRegisterSentIndexRoute
  '/users/profile': typeof BaseLayoutUsersProfileIndexRoute
  '/chats/$chatId/faqs/create': typeof BaseLayoutChatsChatIdFaqsCreateRoute
  '/chats/$chatId/operators/create': typeof BaseLayoutChatsChatIdOperatorsCreateRoute
  '/chat-users/$chatUserId/threads': typeof BaseLayoutChatUsersChatUserIdThreadsIndexRoute
  '/chats/$chatId/documents': typeof BaseLayoutChatsChatIdDocumentsIndexRoute
  '/chats/$chatId/faqs': typeof BaseLayoutChatsChatIdFaqsIndexRoute
  '/chats/$chatId/operators': typeof BaseLayoutChatsChatIdOperatorsIndexRoute
  '/chats/$chatId/faqs/$faqId/edit': typeof BaseLayoutChatsChatIdFaqsFaqIdEditRoute
  '/chats/$chatId/operators/$operatorId/edit': typeof BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute
}

export interface FileRoutesByTo {
  '': typeof AuthLayoutRouteWithChildren
  '/': typeof BaseLayoutIndexRoute
  '/chats/create': typeof BaseLayoutChatsCreateRoute
  '/organizations/create': typeof BaseLayoutOrganizationsCreateRoute
  '/users/create': typeof BaseLayoutUsersCreateRoute
  '/forgotten-password': typeof AuthLayoutForgottenPasswordIndexRoute
  '/login': typeof AuthLayoutLoginIndexRoute
  '/register': typeof AuthLayoutRegisterIndexRoute
  '/reset-password': typeof AuthLayoutResetPasswordIndexRoute
  '/verify': typeof AuthLayoutVerifyIndexRoute
  '/chat-users': typeof BaseLayoutChatUsersIndexRoute
  '/chats': typeof BaseLayoutChatsIndexRoute
  '/organizations': typeof BaseLayoutOrganizationsIndexRoute
  '/threads': typeof BaseLayoutThreadsIndexRoute
  '/users': typeof BaseLayoutUsersIndexRoute
  '/chats/$chatId/edit': typeof BaseLayoutChatsChatIdEditRoute
  '/organizations/$organizationId/edit': typeof BaseLayoutOrganizationsOrganizationIdEditRoute
  '/users/$userId/edit': typeof BaseLayoutUsersUserIdEditRoute
  '/forgotten-password/sent': typeof AuthLayoutForgottenPasswordSentIndexRoute
  '/register/sent': typeof AuthLayoutRegisterSentIndexRoute
  '/users/profile': typeof BaseLayoutUsersProfileIndexRoute
  '/chats/$chatId/faqs/create': typeof BaseLayoutChatsChatIdFaqsCreateRoute
  '/chats/$chatId/operators/create': typeof BaseLayoutChatsChatIdOperatorsCreateRoute
  '/chat-users/$chatUserId/threads': typeof BaseLayoutChatUsersChatUserIdThreadsIndexRoute
  '/chats/$chatId/documents': typeof BaseLayoutChatsChatIdDocumentsIndexRoute
  '/chats/$chatId/faqs': typeof BaseLayoutChatsChatIdFaqsIndexRoute
  '/chats/$chatId/operators': typeof BaseLayoutChatsChatIdOperatorsIndexRoute
  '/chats/$chatId/faqs/$faqId/edit': typeof BaseLayoutChatsChatIdFaqsFaqIdEditRoute
  '/chats/$chatId/operators/$operatorId/edit': typeof BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authLayout': typeof AuthLayoutRouteWithChildren
  '/_baseLayout': typeof BaseLayoutRouteWithChildren
  '/_baseLayout/': typeof BaseLayoutIndexRoute
  '/_baseLayout/chats/create': typeof BaseLayoutChatsCreateRoute
  '/_baseLayout/organizations/create': typeof BaseLayoutOrganizationsCreateRoute
  '/_baseLayout/users/create': typeof BaseLayoutUsersCreateRoute
  '/_authLayout/forgotten-password/': typeof AuthLayoutForgottenPasswordIndexRoute
  '/_authLayout/login/': typeof AuthLayoutLoginIndexRoute
  '/_authLayout/register/': typeof AuthLayoutRegisterIndexRoute
  '/_authLayout/reset-password/': typeof AuthLayoutResetPasswordIndexRoute
  '/_authLayout/verify/': typeof AuthLayoutVerifyIndexRoute
  '/_baseLayout/chat-users/': typeof BaseLayoutChatUsersIndexRoute
  '/_baseLayout/chats/': typeof BaseLayoutChatsIndexRoute
  '/_baseLayout/organizations/': typeof BaseLayoutOrganizationsIndexRoute
  '/_baseLayout/threads/': typeof BaseLayoutThreadsIndexRoute
  '/_baseLayout/users/': typeof BaseLayoutUsersIndexRoute
  '/_baseLayout/chats/$chatId/edit': typeof BaseLayoutChatsChatIdEditRoute
  '/_baseLayout/organizations/$organizationId/edit': typeof BaseLayoutOrganizationsOrganizationIdEditRoute
  '/_baseLayout/users/$userId/edit': typeof BaseLayoutUsersUserIdEditRoute
  '/_authLayout/forgotten-password/sent/': typeof AuthLayoutForgottenPasswordSentIndexRoute
  '/_authLayout/register/sent/': typeof AuthLayoutRegisterSentIndexRoute
  '/_baseLayout/users/profile/': typeof BaseLayoutUsersProfileIndexRoute
  '/_baseLayout/chats/$chatId/faqs/create': typeof BaseLayoutChatsChatIdFaqsCreateRoute
  '/_baseLayout/chats/$chatId/operators/create': typeof BaseLayoutChatsChatIdOperatorsCreateRoute
  '/_baseLayout/chat-users/$chatUserId/threads/': typeof BaseLayoutChatUsersChatUserIdThreadsIndexRoute
  '/_baseLayout/chats/$chatId/documents/': typeof BaseLayoutChatsChatIdDocumentsIndexRoute
  '/_baseLayout/chats/$chatId/faqs/': typeof BaseLayoutChatsChatIdFaqsIndexRoute
  '/_baseLayout/chats/$chatId/operators/': typeof BaseLayoutChatsChatIdOperatorsIndexRoute
  '/_baseLayout/chats/$chatId/faqs/$faqId/edit': typeof BaseLayoutChatsChatIdFaqsFaqIdEditRoute
  '/_baseLayout/chats/$chatId/operators/$operatorId/edit': typeof BaseLayoutChatsChatIdOperatorsOperatorIdEditRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/'
    | '/chats/create'
    | '/organizations/create'
    | '/users/create'
    | '/forgotten-password'
    | '/login'
    | '/register'
    | '/reset-password'
    | '/verify'
    | '/chat-users'
    | '/chats'
    | '/organizations'
    | '/threads'
    | '/users'
    | '/chats/$chatId/edit'
    | '/organizations/$organizationId/edit'
    | '/users/$userId/edit'
    | '/forgotten-password/sent'
    | '/register/sent'
    | '/users/profile'
    | '/chats/$chatId/faqs/create'
    | '/chats/$chatId/operators/create'
    | '/chat-users/$chatUserId/threads'
    | '/chats/$chatId/documents'
    | '/chats/$chatId/faqs'
    | '/chats/$chatId/operators'
    | '/chats/$chatId/faqs/$faqId/edit'
    | '/chats/$chatId/operators/$operatorId/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/'
    | '/chats/create'
    | '/organizations/create'
    | '/users/create'
    | '/forgotten-password'
    | '/login'
    | '/register'
    | '/reset-password'
    | '/verify'
    | '/chat-users'
    | '/chats'
    | '/organizations'
    | '/threads'
    | '/users'
    | '/chats/$chatId/edit'
    | '/organizations/$organizationId/edit'
    | '/users/$userId/edit'
    | '/forgotten-password/sent'
    | '/register/sent'
    | '/users/profile'
    | '/chats/$chatId/faqs/create'
    | '/chats/$chatId/operators/create'
    | '/chat-users/$chatUserId/threads'
    | '/chats/$chatId/documents'
    | '/chats/$chatId/faqs'
    | '/chats/$chatId/operators'
    | '/chats/$chatId/faqs/$faqId/edit'
    | '/chats/$chatId/operators/$operatorId/edit'
  id:
    | '__root__'
    | '/_authLayout'
    | '/_baseLayout'
    | '/_baseLayout/'
    | '/_baseLayout/chats/create'
    | '/_baseLayout/organizations/create'
    | '/_baseLayout/users/create'
    | '/_authLayout/forgotten-password/'
    | '/_authLayout/login/'
    | '/_authLayout/register/'
    | '/_authLayout/reset-password/'
    | '/_authLayout/verify/'
    | '/_baseLayout/chat-users/'
    | '/_baseLayout/chats/'
    | '/_baseLayout/organizations/'
    | '/_baseLayout/threads/'
    | '/_baseLayout/users/'
    | '/_baseLayout/chats/$chatId/edit'
    | '/_baseLayout/organizations/$organizationId/edit'
    | '/_baseLayout/users/$userId/edit'
    | '/_authLayout/forgotten-password/sent/'
    | '/_authLayout/register/sent/'
    | '/_baseLayout/users/profile/'
    | '/_baseLayout/chats/$chatId/faqs/create'
    | '/_baseLayout/chats/$chatId/operators/create'
    | '/_baseLayout/chat-users/$chatUserId/threads/'
    | '/_baseLayout/chats/$chatId/documents/'
    | '/_baseLayout/chats/$chatId/faqs/'
    | '/_baseLayout/chats/$chatId/operators/'
    | '/_baseLayout/chats/$chatId/faqs/$faqId/edit'
    | '/_baseLayout/chats/$chatId/operators/$operatorId/edit'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  BaseLayoutRoute: typeof BaseLayoutRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  BaseLayoutRoute: BaseLayoutRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authLayout",
        "/_baseLayout"
      ]
    },
    "/_authLayout": {
      "filePath": "_authLayout.tsx",
      "children": [
        "/_authLayout/forgotten-password/",
        "/_authLayout/login/",
        "/_authLayout/register/",
        "/_authLayout/reset-password/",
        "/_authLayout/verify/",
        "/_authLayout/forgotten-password/sent/",
        "/_authLayout/register/sent/"
      ]
    },
    "/_baseLayout": {
      "filePath": "_baseLayout.tsx",
      "children": [
        "/_baseLayout/",
        "/_baseLayout/chats/create",
        "/_baseLayout/organizations/create",
        "/_baseLayout/users/create",
        "/_baseLayout/chat-users/",
        "/_baseLayout/chats/",
        "/_baseLayout/organizations/",
        "/_baseLayout/threads/",
        "/_baseLayout/users/",
        "/_baseLayout/chats/$chatId/edit",
        "/_baseLayout/organizations/$organizationId/edit",
        "/_baseLayout/users/$userId/edit",
        "/_baseLayout/users/profile/",
        "/_baseLayout/chats/$chatId/faqs/create",
        "/_baseLayout/chats/$chatId/operators/create",
        "/_baseLayout/chat-users/$chatUserId/threads/",
        "/_baseLayout/chats/$chatId/documents/",
        "/_baseLayout/chats/$chatId/faqs/",
        "/_baseLayout/chats/$chatId/operators/",
        "/_baseLayout/chats/$chatId/faqs/$faqId/edit",
        "/_baseLayout/chats/$chatId/operators/$operatorId/edit"
      ]
    },
    "/_baseLayout/": {
      "filePath": "_baseLayout/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/create": {
      "filePath": "_baseLayout/chats/create.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/organizations/create": {
      "filePath": "_baseLayout/organizations/create.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/users/create": {
      "filePath": "_baseLayout/users/create.tsx",
      "parent": "/_baseLayout"
    },
    "/_authLayout/forgotten-password/": {
      "filePath": "_authLayout/forgotten-password/index.tsx",
      "parent": "/_authLayout"
    },
    "/_authLayout/login/": {
      "filePath": "_authLayout/login/index.tsx",
      "parent": "/_authLayout"
    },
    "/_authLayout/register/": {
      "filePath": "_authLayout/register/index.tsx",
      "parent": "/_authLayout"
    },
    "/_authLayout/reset-password/": {
      "filePath": "_authLayout/reset-password/index.tsx",
      "parent": "/_authLayout"
    },
    "/_authLayout/verify/": {
      "filePath": "_authLayout/verify/index.tsx",
      "parent": "/_authLayout"
    },
    "/_baseLayout/chat-users/": {
      "filePath": "_baseLayout/chat-users/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/": {
      "filePath": "_baseLayout/chats/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/organizations/": {
      "filePath": "_baseLayout/organizations/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/threads/": {
      "filePath": "_baseLayout/threads/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/users/": {
      "filePath": "_baseLayout/users/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/edit": {
      "filePath": "_baseLayout/chats/$chatId/edit.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/organizations/$organizationId/edit": {
      "filePath": "_baseLayout/organizations/$organizationId/edit.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/users/$userId/edit": {
      "filePath": "_baseLayout/users/$userId/edit.tsx",
      "parent": "/_baseLayout"
    },
    "/_authLayout/forgotten-password/sent/": {
      "filePath": "_authLayout/forgotten-password/sent/index.tsx",
      "parent": "/_authLayout"
    },
    "/_authLayout/register/sent/": {
      "filePath": "_authLayout/register/sent/index.tsx",
      "parent": "/_authLayout"
    },
    "/_baseLayout/users/profile/": {
      "filePath": "_baseLayout/users/profile/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/faqs/create": {
      "filePath": "_baseLayout/chats/$chatId/faqs/create.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/operators/create": {
      "filePath": "_baseLayout/chats/$chatId/operators/create.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chat-users/$chatUserId/threads/": {
      "filePath": "_baseLayout/chat-users/$chatUserId/threads/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/documents/": {
      "filePath": "_baseLayout/chats/$chatId/documents/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/faqs/": {
      "filePath": "_baseLayout/chats/$chatId/faqs/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/operators/": {
      "filePath": "_baseLayout/chats/$chatId/operators/index.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/faqs/$faqId/edit": {
      "filePath": "_baseLayout/chats/$chatId/faqs/$faqId/edit.tsx",
      "parent": "/_baseLayout"
    },
    "/_baseLayout/chats/$chatId/operators/$operatorId/edit": {
      "filePath": "_baseLayout/chats/$chatId/operators/$operatorId/edit.tsx",
      "parent": "/_baseLayout"
    }
  }
}
ROUTE_MANIFEST_END */
