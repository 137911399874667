import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { AppSettings } from '@/constants/AppSettings.ts';
import { ChatForm } from '@/features/chats/components/ChatForm';
import { copyToClipboard } from '@/lib/copyToClipboard.ts';
import { trpc } from '@/services/trpc';

const populate = ['documentsCount', 'embeddingsCount'];

export const Route = createFileRoute('/_baseLayout/chats/$chatId/edit')({
  component: ChatsEditPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin, Role.Admin]),
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId, populate }),
});

export function ChatsEditPage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'chats', 'glossary']);
  const [data] = trpc.chats.findOne.useSuspenseQuery({
    id: chatId,
    populate,
  });

  return (
    <FormPage
      title={`${t('chats:texts.configuration')} - ${data.name}`}
      actions={
        <Button
          onClick={() => {
            copyToClipboard(
              `${AppSettings.playgroundURL}/?chatId=${chatId}`,
            ).then(() => {
              toast.success(t('chats:toasts.copyLink.success.title'), {
                description: t('chats:toasts.copyLink.success.description'),
              });
            });
          }}
        >
          {t('glossary:actions.share')}
        </Button>
      }
      breadcrumbs={[
        { label: t('globals:routeNames.agent') },
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
      ]}
    >
      <ChatForm data={data} />
    </FormPage>
  );
}
