import { Role } from '@kanbu/schema/enums';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';

export type ChatConfigFieldsProps = {};

export const ChatConfigFields = memo(
  function ChatConfigFields({}: ChatConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums', 'chats']);
    const { hasAccess } = useAccess();

    const { schema } = useFieldsSchema(
      [
        {
          name: 'chatbotConfig.maxCharacters',
          label: t('glossary:labels.maxCharacters'),
          visible: hasAccess([Role.SuperAdmin]),
          type: 'number',
          showOptional: false,
        },
        {
          name: 'chatbotConfig.disclaimerContact',
          label: t('glossary:labels.disclaimerContact'),
        },
      ],
      [t],
    );

    return (
      <Card title={t('glossary:labels.chatbotConfig')}>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
