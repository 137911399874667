import type { ChatType } from '@kanbu/schema';
import { createChatSchema, updateChatSchema } from '@kanbu/schema/contracts';
import { Role } from '@kanbu/schema/enums';
import { File, FileScan } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { Protected } from '@/components/protected/Protected';
import { useBoundStore } from '@/store/store';

import { AgentConfigFields } from './formFields/AgentConfigFields';
import { BasicConfigFields } from './formFields/BasicConfigFields';
import { ChatbotScriptFields } from './formFields/ChatbotScriptFields';
import { ChatConfigFields } from './formFields/ChatConfigFields';
import { ChatPlacementFields } from './formFields/ChatPlacementFields';
import { ChatThemeFields } from './formFields/ChatThemeFields';
import { EvaluationFields } from './formFields/EvaluationFields';
import { useChatMutation } from '../useChatMutation';

export type ChatFormProps = {
  data?: ChatType;
};

export const ChatForm = memo(function ChatForm({ data }: ChatFormProps) {
  const { t } = useTranslation(['glossary']);
  const { setChat } = useBoundStore(state => ({
    setChat: state.setChat,
  }));

  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useChatMutation();

  return (
    <EntityCRUDForm
      /**
       * We need to pass key to ChatForm to force re-render when chatId changes,
       * this is to properly reset initial form data when chatId changes.
       */
      key={data?.id}
      initialData={data}
      createSchema={createChatSchema}
      updateSchema={updateChatSchema}
      onCreate={async values => {
        const newChat = await create(values);

        // Set new chat as active
        setChat(newChat);

        // Return new chat
        return newChat;
      }}
      onUpdate={update}
      onRemove={remove}
      basePath='/chats'
      idParamName='chatId'
    >
      <EntityCRUDForm.Col>
        <BasicConfigFields />
        <Protected roles={[Role.SuperAdmin]}>
          <AgentConfigFields />
        </Protected>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <Protected roles={[Role.SuperAdmin]}>
          <ChatConfigFields />
        </Protected>

        <ChatThemeFields />
        <ChatPlacementFields />
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <MetaFields data={data}>
          <Protected roles={[Role.SuperAdmin]}>
            <DisplayValue
              label={t('glossary:labels.documentsCount')}
              Icon={File}
            >
              {data?.documentsCount ?? '0'}
            </DisplayValue>
            <DisplayValue
              label={t('glossary:labels.embeddingsCount')}
              Icon={FileScan}
            >
              {data?.embeddingsCount ?? '0'}
            </DisplayValue>
          </Protected>
        </MetaFields>
        <Protected roles={[Role.SuperAdmin]}>
          <EvaluationFields data={data} />
        </Protected>
        <ChatbotScriptFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
