import type { OperatorType } from '@kanbu/schema';
import {
  createOperatorSchema,
  updateOperatorSchema,
} from '@kanbu/schema/contracts';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { useOperatorMutation } from './useOperatorMutation';

export type OperatorFormProps = {
  data?: OperatorType;
  chatId: string;
};

export const OperatorForm = memo(function OperatorForm({
  data,
  chatId,
}: OperatorFormProps) {
  const { t } = useTranslation(['glossary']);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useOperatorMutation();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'firstName',
        label: t('glossary:labels.firstName'),
        required: true,
      },
      {
        name: 'lastName',
        label: t('glossary:labels.lastName'),
        required: true,
      },
      {
        name: 'email',
        label: t('glossary:labels.email'),
        required: true,
      },
      {
        name: 'phone',
        label: t('glossary:labels.phone'),
      },
    ],
    [t],
  );

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createOperatorSchema.omit({ chat: true })}
      updateSchema={updateOperatorSchema.omit({ chat: true })}
      onCreate={values => create({ ...values, chat: chatId })}
      onUpdate={update}
      onRemove={remove}
      basePath={`/chats/${chatId}/operators`}
      idParamName='operatorId'
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col />
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
