export const THEME_PRESETS = {
  light: {
    primary: '#388BFD',
    primaryFg: '#ffffff',

    secondary: '#66BB6A',
    secondaryFg: '#ffffff',

    background: '#ffffff',
    backgroundSecondary: '#f2f2f2',

    foreground: '#333333',
    placeholder: '#aaaaaa',
  },
  dark: {
    primary: '#5A7BFD',
    primaryFg: '#FFFFFF',

    secondary: '#66BB6A',
    secondaryFg: '#FFFFFF',

    background: '#121212',
    backgroundSecondary: '#1F1F1F',

    foreground: '#EAEAEA',
    placeholder: '#AAAAAA',
  },
  kanbu: {
    primary: '#FFCF52',
    primaryFg: '#242424',

    secondary: '#8A2BE2',
    secondaryFg: '#fff',

    background: '#fff',
    backgroundSecondary: '#f0f0f0',

    foreground: '#070606',
    placeholder: '#898989',
  },
} as const;

export const AgentDefaults = {
  name: 'AI Honza',
  defaultThemePreset: 'light',
  defaultTheme: THEME_PRESETS.light,
} as const;
